@keyframes liveBackground {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  .animated-background {
    background-color: linear-gradient(45deg, #0e9594, #0e9594, #0e9594, #0e9594);
    background-size: 400% 400%;
    animation: liveBackground 4s infinite;
    text-align: center;
    color: white;
    padding: 2rem;
  }