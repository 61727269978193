
.participants-grid-container{
    overflow: scroll;
    /* height: auto; */
}
.search-container {
    margin-bottom: 16px;
}

.search-input {
    /* padding: 8px; */
    border-radius: 4px;
    /* border: 1px solid #ccc; */
    width: 100%;
    box-sizing: border-box;
}

.participants-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.participants-table th,
.participants-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.participants-table th {
    background-color: #666;
    color: #fff;
}


.participant-row:hover {
    background-color: #f9f9f9;
}

.pagination-controls {
    margin-top: 16px;
    display: flex;
    justify-content: right;
    align-items: center;
}

.pagination-button {
    background-color: #a8adb1;
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 4px;
    cursor: pointer;
    border-radius: 4px;
}

.pagination-button:disabled {
    background-color: #ddd;
    color: #777;
    cursor: not-allowed;
}

.page-info {
    margin: 0 16px;
}

.page-size-select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-left: 16px;
}
